//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Profesor Bencrack!
        <iframe title="Bencrack" src="https://scratch.mit.edu/projects/557626362/embed" allowtransparency="true" width="485" height="402" frameborder="0" scrolling="no" allowfullscreen></iframe>
      </header>
    </div>
  );
}

export default App;
